@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background-color: #f5f8fc;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 11;
  overflow-x: scroll;
  /* padding-top: 4rem; */
}
.login-staff {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 5rem;
  width: 550px;
}
.login-staff form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.login-staff form input {
  width: 100%;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #e6e7f5;
  border: none;
}
.login-staff h1 {
  color: #000;
  font-size: 27.2px;
  font-weight: 600;
  letter-spacing: -0.816px;
  margin-bottom: 3rem;
}
.login-staff form button {
  width: 100%;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #00acef;
  border: none;
  color: #fff;
  font-size: 18.084px;
  font-weight: 600;
  line-height: 120%; /* 105.979% */
  letter-spacing: -0.363px;
}
.modal-container {
  border-radius: 18px;
  background: #fff;
  padding: 50px 115px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
.max-container {
  max-width: 750px;
  margin: 0 auto;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
.navbar > div {
  cursor: pointer;
}
.nav-logo {
  color: #000;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 26.798px;
  font-weight: 600;
  letter-spacing: -0.804px;
}
.home-welcome-card {
  width: 100%;
  border-radius: 28.005px;
  background: #00acef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10%;
  padding-left: 3%;
  overflow: hidden;
  margin-top: 2rem;
  gap: 2rem;
}
.profile-welcome-card {
  margin-top: 3rem;
  width: 100%;
  border-radius: 28.005px;
  background: #00acef;
  padding: 40px 30px;
}
.home-welcome-card-img img {
  position: relative;
  top: 10px;
  left: 10px;
}
.welcome-text h1 {
  color: #fff;
  font-size: 37.467px;
  font-weight: 600;
  line-height: 120%; /* 44.96px */
  letter-spacing: -1.124px;
}
.welcome-text p {
  color: #fff;
  font-size: 20px;
  margin-top: 7px;
  font-weight: 500;
  opacity: 0.8;
  line-height: 119%; /* 26.008px */
  letter-spacing: -0.656px;
}
.staff-operations {
  margin-top: 2rem;
}
.staff-operations-list {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  gap: 1rem;
}
.arrow {
  border-radius: 64.308px;
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow img {
  width: 18px;
  transition: all 0.4s ease;
}
.staff-operations-list > div {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 36.246px;
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 6px;
  padding-left: 24px;
  transition: all 0.3s ease;
}
.staff-operations-list > div:hover {
  background: #e6e7f5;
}
.staff-operations-list > div:hover .arrow img {
  transform: rotate(-45deg);
}
.staff-operations h1 {
  color: #000;
  font-size: 27.2px;
  font-weight: 600;
  letter-spacing: -0.816px;
}
.staff-operations-list-item h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18.708px;
  font-weight: 600;
  letter-spacing: -0.561px;
}
.operation-page {
  margin-top: 3rem;
}
.input-field {
  width: 100%;
}
.input-field input,
.input-field select {
  width: 100%;
  border-radius: 15px;
  background: #f2f3fb;
  color: #000;
  font-size: 17px;
  font-weight: 500;
  padding: 14px 19px;
  margin-top: 2rem;
  border: none;
  letter-spacing: -0.45px;
}
.table {
  width: 100%;
  margin-top: 3rem;
}
.table-inner {
  width: 100%;
}
.table table {
  width: 100%;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
}
.table table td {
  padding: 10px 0;
}
.table table tr {
  border: none;
  transition: all 0.3s ease;
}

.table table th {
  border: none;
  padding: 10px 0;
  /* background: rgba(230, 231, 245, 0.6); */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.54px;
  color: rgba(0, 0, 0, 0.7);
}
.table table td {
  border: none;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: rgba(0, 0, 0, 0.7);
}
.table table td.green {
  color: rgb(2, 194, 15);
}
.table tbody tr {
  border-radius: 10px;
  padding: 10px 0;
}
.table tbody tr td div {
  background-color: #fff;
  padding: 14px 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.table tbody tr td:nth-child(1) div {
  border-left: 1px solid #efefef;
  border-radius: 10px 0px 0px 10px;
}
.table tbody tr td:last-child div {
  border-right: 1px solid #efefef;
  border-radius: 0px 10px 10px 0px;
}
.table tbody tr:hover {
  cursor: pointer;
}
.table tbody tr:hover td:nth-child(1) div {
  border-left: 1.4px solid #7550e0;
}
.table tbody tr:hover td div {
  border-top: 1.4px solid #7550e0;
  border-bottom: 1.4px solid #7550e0;
}
.table tbody tr:hover td:last-child div {
  border-right: 1.4px solid #7550e0;
}

.staff-operations p {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.561px;
  margin-top: 1rem;
}
.update-status-fields {
  margin-top: 2rem;
}
.update-status-fields .input-field input {
  margin-top: 0;
}
.input-field p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.561px;
  margin-top: 1rem;
  margin-bottom: 7px;
}
.update-status-btns {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
.user-info-modal {
  padding: 20px 40px;
  width: 600px;
}
.update-status-btns button,
.scanner button,
.user-info-modal button {
  padding: 10px;
  border-radius: 8px;
  background-color: #00acef;
  border: none;
  color: #fff;
  font-size: 18.084px;
  font-weight: 600;
  line-height: 120%; /* 105.979% */
  letter-spacing: -0.363px;
  width: 100%;
}
.scanner button {
  margin: 2rem 0;
}
.user-info-modal button {
  margin-top: 1.3rem;
}
button {
  transition: all 0.3s ease;
}
button:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.update-status-btns button.secondary {
  background-color: transparent;
  border: 1.4px solid #000;
  color: #000;
}
.user-data {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 2rem;
}
.user-data-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user-data-list-item {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.user-data-list-item p {
  font-size: 18px;
  font-weight: 500;
}
.user-data-list-item p:nth-child(1) {
  opacity: 0.7;
}
.inner-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}
.user-data-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* flex-direction: row; */
}
.scanner {
  text-align: center;
  margin-top: 3rem;
}
.qr-scanner {
  margin-top: 1rem;
}
.count {
  background-color: #00acef;
  width:25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;left: -3px;top: -3px;
  color: #fff;
  font-size: 12px;
}
.staff-operations-list-item {
  position: relative;
}
@media (max-width: 900px) {
  .login-staff {
    width: 90%;
  }
  .user-data-list {
    flex-direction: column;
    /* margin-bottom: 0.5rem; */
  }
  .inner-flex {
    gap: 7px;
  }
  .user-info-modal {
    width: 350px;
  }
  .user-data-list {
    gap: 10px;
  }
  .user-data-list-item p {
    font-size: 16px;
  }
  .user-data-list-item p:nth-child(2) {
    font-size: 14px;
  }
  .max-container {
    max-width: unset;
    width: 90%;
    margin: 0 auto;
  }
  .scanner {
    margin-top: 2rem;
  }
  .navbar {
    margin-top: 2rem;
  }
  .home-welcome-card {
    align-items: flex-end;
    padding: 1rem;
    padding-bottom: 0;
  }
  .home-welcome-card-img {
    width: 40%;
  }
  .home-welcome-card-img img {
    width: 100%;
  }
  .welcome-text {
    position: relative;
    margin-bottom: 1.6rem;
  }
  .welcome-text h1 {
    font-size: 24px;
    line-height: 120%;
  }
  .nav-logo {
    font-size: 18px;
  }
  .update-status-btns button {
    font-size: 14px;
  }
  .table table th {
    font-size: 16px;
  }
  .table table {
    /* min-width: 740px; */
  }
  .table {
    padding: 0px;
  }
  .table table td {
    font-size: 14px;
  }
  .nav-user img {
    width: 35px;
  }
  .nav-logo img {
    width: 38px;
  }
  .staff-operations h1 {
    font-size: 20px;
  }
  .staff-operations-list > div {
    padding: 4px;
    padding-left: 18px;
  }
  .staff-operations-list {
    gap: 0.7rem;
  }
  .staff-operations-list-item h2 {
    font-size: 14px;
  }
  .welcome-text p {
    font-size: 14px;
  }
  .table table {
    /* min-width: 430px; */
    border-spacing: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
  }
}
